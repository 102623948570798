.slide {

  max-width: 100%;
  overflow: hidden;
}

.imagem {
     width: 100%;
    height: auto;
   
}


@media (max-width: 768px) {
  .imagem {
    width: 100%;
    height: 110px;
    object-fit: cover; 
    
}
}