body {
    background-color: #EEEEEE;
    margin: 0;
    font-family: Arial, sans-serif; 
  }
  
  .news-grid {
    display: grid;
    flex-direction: column;
    max-width: 900px;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 20px;
    padding: 20px;
  }


a {
  text-decoration: none;
}

  
  .news-card {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
    background-color: white;
    transition: transform 0.2s; 
    text-decoration: none;
  }
  

  .news-card:hover {
    transform: scale(1.1); 
    text-decoration: none;
  }
  
  .news-card img {
    max-width: 100%;
    height: auto;
    text-decoration: none;
  }
  
  .news-card h2 {
    font-size: 1.1rem;
    font-family: 'Nunito', sans-serif; 
    font-weight: bold; 
    color: #333; 
    text-align: center;
    margin-top: 10px; 
    text-decoration: none;
    
  }
  
  .news-card p {
    font-size: 0.9rem;
    font-family: 'Nunito', sans-serif; 
    color: #666; 
    text-align: center; 
    margin-top: 10px; 
    text-decoration: none;
  }
  

  .service-grid {
    width: 240px;
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 10px;
    padding: 60px;
    padding-top: 20px;
    margin-top: 5px; 
  }
  
  .service-card {
    width: 118px;
    height: 118px;
    border-radius: 6px;
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
    background-color: white;
    transition: transform 0.2s;
    cursor: pointer;
    text-decoration: none;
  }
   
    .service-card img {
      max-width: 55px; 
      height: auto;
    }
  
    .service-card p {
      text-decoration: none;
      font-size: 13px;
      font-family: 'Nunito', sans-serif;
      color: #4f4f4f;
      text-align: center;
      margin-top: 8px;
      
      text-decoration: none;
    }

    .service-card a {
      text-decoration: none;
    }
  
  
 
  .service-card:hover {
    transform: scale(1.05); 
  }

 

  .logosala{
    margin-bottom: 18px;
    align-items: center;
  }

  .csala{
    display: flex;
  justify-content: center; 
  align-items: center;
 
  }

  @media (max-width: 768px) {
    .news-grid-container {
      grid-template-columns: 1fr;
    }

    .service-grid {
        width: auto !important;
        align-content: center !important;
        align-items: center !important;
        display: grid ;
        grid-template-columns: repeat(2, 1fr); 
        padding: 25px !important;
      
    }   
     
    .news-grid {
       
        grid-template-columns: 1fr !important;
      }

    .linha {
        width: 100% !important;
      }
      .linha span {
        font-size: 11px !important;
      }
    
  }
  

  .service-title {
    font-size: 1.5rem; 
    font-weight: bold;
    text-align: center;
    margin-top: 15px;
  }

  .linha {
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, #0a35e1, #09ebfb);
    margin: 10px auto;
    position: relative;
  }
  
  .linha span {
    font-family: 'Bebas Neue', sans-serif;
    letter-spacing: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 20px;
    transform: translate(-50%, -50%);
    background: #EEEEEE;
    padding: 0 10px;
  }

  .table-wrap {
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .table th,
  .table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #e6e6e6;
  }
  
  .table th {
    background-color: #306aff;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
  }
  
  .table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .table tbody tr:hover {
    background-color: #f5f5f5;
  }
  
  select {
    padding: 8px;
    margin-right: 15px;
  }
  
  
  @media (max-width: 768px) {
    .table th,
    .table td {
      font-size: 14px;
    }
  }
  
  