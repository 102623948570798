
.containerB {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #333; 
}

@media (max-width: 768px) { 
  .btn-center {
    display: flex;
    justify-content: right;
  }
}


.texto-personalizado {
  color: white; 
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.botao-personalizado {
  background-color: #3498db; 
  color: white; 
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.botao-personalizado:hover {
  background-color: #2980b9; 
}
