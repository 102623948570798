
.navbar-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .small-logo {
    content: url('../../../public/logo512.png') !important;
  }
}

/* .header-background {
  background: linear-gradient(to bottom, #096cff, #3498DB ); 

}

.header-background-menu {
  background: linear-gradient(to bottom, #52adea, #096cff); 

}
 */
.btn-custom-meu {
  background-color: white;
  color: blue;
  border-color: blue;
  font-weight: normal;
  transition: color 0.3s, font-weight 0.3s;
}

.btn-custom-meu:hover {
  color: white;
  font-weight: bold;
}

.estiloData{
  color: white;
}


@media (max-width: 768px) {
  .logogurjao{
    margin-left: 10% !important;
  }


  .linksredes{
    display: none !important;
  }

  .estiloData{
    color: white;
    display: none;
    
  }


}


